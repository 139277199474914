import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.0.1_@babel+core@7.23.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.0.1_@babel+core@7.23.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/brett-garwood-asZVvgMGshc-unsplash.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/clay-banks-3IFYE6UHFBo-unsplash.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/demo.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/markus-winkler-zYBR4TN46EE-unsplash.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/particlez.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/space.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/tensorflow.jpeg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/xps-6uneKLGrJPs-unsplash.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Fathom/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/footer.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/Guitar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Holopin/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/RecentArticles/RecentArticles.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Sidebar/Sidebar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Theme/context.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Theme/ThemeButton.tsx")